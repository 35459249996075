import type { RichTextSection } from "@/modules/corporate/types/sections"
import type { CSSProperties, FunctionComponent } from "react"

import cn from "classnames"
import Image from "next/image"

import { Button } from "@jobteaser/ui-kit/components/Button"
import { Layout } from "@jobteaser/ui-kit/components/Layout/Layout"
import { Text, TEXT_TYPES, TEXT_WEIGHTS } from "@jobteaser/ui-kit/components/Text/Text"

import { InternalLink } from "@/modules/corporate/components/InternalLink"
import { MediaVideo } from "@/modules/corporate/components/MediaVideo/MediaVideo"
import { URL_PATHNAME } from "@/modules/corporate/constants"
import quote from "@/modules/corporate/images/quote.svg"
import { useCorporateTrackingClick } from "@/modules/corporate/tracking/useCorporateTrackingClick"
import { RichText as RichTextComponent } from "@/modules/kontent/components/RichText"
import { useLocale } from "@/modules/locales/useLocale"
import { getAssetImportUrl } from "@/modules/staticAssets/getAssetImportUrl"

import styles from "./RichText.module.css"

export type RichTextPropsType = {
  section: RichTextSection
}

const RICH_TEXT_NB_LIMIT_BUTTONS = 3

export const RichText: FunctionComponent<RichTextPropsType> = ({ section }) => {
  const locale = useLocale()
  const trackClickEvent = useCorporateTrackingClick()

  const hasTextOnRight = section.textPlacement.includes("right")
  const hasTextOnLeft = section.textPlacement.includes("left")
  const hasStepNumber = !!section.stepNumber
  const hasImage = !!section.images.length
  const hasTwoImages = section.images.length === 2
  const hasVideo = !!section.videoYoutube
  const hasMedia = hasImage || hasVideo

  const richTextButtons = section.ctaLinks.slice(0, RICH_TEXT_NB_LIMIT_BUTTONS).map(ctaLink => {
    if (ctaLink.type === "growth___corporate_external_link") {
      return {
        label: ctaLink.label,
        type: ctaLink.type,
        url: ctaLink.url,
      }
    }

    return {
      label: ctaLink.linkPageLabel || ctaLink.title || section.title,
      type: ctaLink.type,
      url: ctaLink.slug,
    }
  })

  return (
    <Layout
      className={cn(
        styles.layout,
        { [styles["layout--withNumber"]]: hasStepNumber },
        { [styles["layout--withMedia"]]: hasMedia },
        { [styles["layout--withTwoImage"]]: hasTwoImages },
        { [styles["layout--withTextOnRight"]]: hasTextOnRight },
        { [styles["layout--withTextOnLeft"]]: hasTextOnLeft }
      )}
      hasTopPadding
      hasBottomPadding
    >
      <Layout.Column className={styles.columnText}>
        <div className={styles.textWrapper}>
          {section.text1 && (
            <div className={styles.textContent}>
              <Text
                className={styles.title}
                type={TEXT_TYPES.HEADING_SMALL}
                weight={TEXT_WEIGHTS.BLACK}
                data-number={section.stepNumber || null}
                resetSpacing
              >
                {section.title}
              </Text>
              <RichTextComponent
                value={section.text1}
                wrapperProps={{
                  className: styles.text,
                  tag: "div",
                }}
              />
            </div>
          )}
          {!!section.text2.length && (
            <RichTextComponent
              customComponents={{
                growth___corporate_page: InternalLink,
              }}
              value={section.text2}
              wrapperProps={{
                className: styles.text,
                tag: "div",
              }}
            />
          )}
          {!!section.testimonies.length && (
            <>
              {section.testimonies.map(testimony => (
                <div
                  key={JSON.stringify(testimony.description)}
                  className={styles.testimony}
                  style={{ "--backgroundImage": `url(${getAssetImportUrl(quote)})` } as CSSProperties}
                >
                  <RichTextComponent
                    customComponents={{
                      growth___corporate_page: InternalLink,
                    }}
                    value={testimony.description}
                    wrapperProps={{
                      className: styles.testimonyText,
                      resetSpacing: true,
                      tag: "div",
                    }}
                  />
                  {testimony.authorName && <div className={styles.testimonyText}>{testimony.authorName}</div>}
                </div>
              ))}
            </>
          )}
          {!!richTextButtons.length && (
            <div className={styles.buttonsWrapper}>
              {richTextButtons.map(ctaLink => {
                if (ctaLink.type === "growth___corporate_external_link") {
                  return (
                    <Button
                      key={ctaLink.url}
                      component="a"
                      href={ctaLink.url}
                      target="_blank"
                      rel="noreferer noopener"
                      className={styles.button}
                      onClick={() =>
                        trackClickEvent("corporate_cta_richtext", {
                          clicked_url: ctaLink.url,
                          visual_context: ctaLink.label,
                        })
                      }
                    >
                      {ctaLink.label}
                    </Button>
                  )
                }
                return (
                  <Button
                    key={ctaLink.url}
                    component={InternalLink}
                    page={{ slug: ctaLink.url, title: ctaLink.label }}
                    customTitle={ctaLink.label}
                    className={styles.button}
                    onClick={() =>
                      trackClickEvent("corporate_cta_richtext", {
                        clicked_url: `/${locale}/${URL_PATHNAME}/${ctaLink.url}`,
                        visual_context: ctaLink.label,
                      })
                    }
                  />
                )
              })}
            </div>
          )}
        </div>
      </Layout.Column>
      {hasMedia && (
        <Layout.Column className={styles.columnMedia}>
          {hasImage &&
            section.images.map(image => (
              <picture
                key={image.url}
                className={cn(styles.imageWrapper, { [styles["imageWrapper--multiple"]]: hasTwoImages })}
                style={{ "--heightImage": `${image.height}px`, "--widthImage": `${image.width}px` } as CSSProperties}
              >
                <Image
                  src={image.url}
                  className={styles.image}
                  alt={image.description || ""}
                  width={image.width || undefined}
                  height={image.height || undefined}
                  sizes="(min-width: 48rem) 100vw, 75vw"
                />
              </picture>
            ))}
          {hasVideo && <MediaVideo title="" url={section.videoYoutube} />}
        </Layout.Column>
      )}
    </Layout>
  )
}
